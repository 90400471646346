<template>
  <b-modal
    id="privacy-modal"
    modal-class="common-modal"
    hide-footer
  >
    <template slot="modal-header">
      <h5 class="modal-title">{{ $t('general.privacy') }}</h5>
      <button @click="hideModal()" class="close"></button>
    </template>

    <template slot="default">
      <div class="info" v-html="$t('general.privacy')" style="text-align: justify;"></div>

      <div class="form-actions center">
        <b-button type="button" variant="bordered" @click="hideModal()">{{ $t('general.close') }}</b-button>
      </div>
    </template>
  </b-modal>
</template>

<style lang="scss" scoped>
@import "./Privacy";
</style>

<script>
export default {
  methods: {
    hideModal() {
      this.$bvModal.hide('privacy-modal');
    },
  },
};
</script>
